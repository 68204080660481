<template>
  <div class="flex flex-row justify-between items-center">
    <span
      :class="{
        '-mb-1 label-m': false,
        'headline-xxs': !false,
      }"
    >
      {{ computedLabel }}
    </span>

    <NuxtLink
      v-if="link"
      class="text-xs font-semibold text-gray-700 font-sans"
      :to="link"
      >{{ $t(linkLabel) }}</NuxtLink
    >
  </div>
</template>

<script setup lang="ts">
const indexStore = useIndexStore()
const nuxtApp = useNuxtApp()

const props = withDefaults(
  defineProps<{
    label?: string
    linkLabel?: string
    link?: string
    translateLabel?: boolean
  }>(),
  { label: '', linkLabel: 'ShowAll', translateLabel: true }
)

const computedLabel = computed(() => {
  if (
    props.label === 'MostRead' &&
    indexStore.currentPaper &&
    indexStore.currentPaper.RecordId !== 1
  ) {
    return `${labelOrTranslation.value} ${indexStore.currentPaper.Name}`
  } else {
    return labelOrTranslation.value
  }
})

const labelOrTranslation = computed(() => {
  if (props.translateLabel && nuxtApp.$te(props.label)) {
    return nuxtApp.$t(props.label)
  } else {
    return props.label
  }
})
</script>
